import axios from '@/common/axios'
import qs from 'qs'

export function addMaterialPurchase (data) {
  return axios({
    method: 'post',
    url: '/storage/materialPurchase/add',
    data: qs.stringify(data)
  })
}

export function deleteMaterialPurchase (id) {
  return axios({
    method: 'delete',
    url: '/storage/materialPurchase/delete/' + id
  })
}

export function updateMaterialPurchase (data) {
  return axios({
    method: 'put',
    url: '/storage/materialPurchase/update',
    data: qs.stringify(data)
  })
}

export function selectMaterialPurchaseInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/materialPurchase/info/' + id
  })
}

export function selectMaterialPurchaseList (query) {
  return axios({
    method: 'get',
    url: '/storage/materialPurchase/list',
    params: query
  })
}
