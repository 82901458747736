import axios from '@/common/axios'
import qs from 'qs'

export function addOrderOut (data) {
  return axios({
    method: 'post',
    url: '/storage/orderOut/add',
    data: qs.stringify(data)
  })
}

export function deleteOrderOut (id) {
  return axios({
    method: 'delete',
    url: '/storage/orderOut/delete/' + id
  })
}

export function updateOrderOut (data) {
  return axios({
    method: 'put',
    url: '/storage/orderOut/update',
    data: qs.stringify(data)
  })
}

export function selectOrderOutInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/orderOut/info/' + id
  })
}

export function selectOrderOutList (query) {
  return axios({
    method: 'get',
    url: '/storage/orderOut/list',
    params: query
  })
}

export function reviewOrderOut (data) {
  return axios({
    method: 'put',
    url: '/storage/orderOut/review',
    data: qs.stringify(data)
  })
}

export function confirmOrderOut (data) {
  return axios({
    method: 'put',
    url: '/storage/orderOut/confirm',
    data: qs.stringify(data)
  })
}

export function selectOrderOutByNo (serialNumber) {
  return axios({
    method: 'get',
    url: '/storage/orderOut/selectByNo/' + serialNumber
  })
}
